header {
  p {
    font-size: 1.6rem;
    margin-bottom: 4rem;

    span {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 0.75em;
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='110' height='9' viewBox='0 0 110 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.61079 7.19191C12.6836 3.29404 65 -1.5 108.974 2.77975' stroke='%23f5f5f5' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        transform: translateX(10%);
        opacity: 0.4;
      }
    }
  }
}

table {
  --projects-gap: clamp(2rem, 8vh, 3.2rem);

  caption-side: top;
  border-collapse: separate;
  border-spacing: 0 var(--projects-gap);
  margin-top: calc(var(--projects-gap) * 2.5);
  margin-bottom: calc(var(--projects-gap) * -1);

  p {
    font-size: 1rem;
  }
}

caption {
  padding: inherit;
  font-weight: 500;
}

tbody {
  vertical-align: top;
}

td {
  &:first-of-type {
    padding-right: 12px;

    @media (min-width: 400px) {
      min-width: 8rem;
    }

    p {
      color: var(--text-contrast-60);
    }
  }

  &:not(:first-of-type) {
    width: 100%;

    a {
      color: var(--text-contrast-100);
      font-family: var(--type-display);
      font-weight: 500;
      margin-bottom: 0.2em;
      position: relative;
      font-size: 1.2rem;
      text-decoration: none;

      &:hover,
      &:focus-visible {
        color: var(--bg-level-0);
        background-color: var(--primary);
        outline: 2px solid var(--primary);

        &::after {
          content: "";
          display: inline-block;
          width: 1em;
          height: 1em;
          margin-bottom: -0.1em;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet' width='20' height='20' fill='none' viewBox='0 0 20 20' aria-hidden='true'%3E%3Cpath fill='%23181a1d' fill-rule='evenodd' d='M6.263 5.825a.556.556 0 00-.181.12.498.498 0 00-.149.387l-.016.656v.006c0 .287.232.567.553.541h4.7l-5.364 5.363-.002.002a.545.545 0 00-.002.76l.483.483a.53.53 0 00.765 0l5.37-5.37V10.3l.01 3.197a.493.493 0 00.095.327.554.554 0 00.449.224l.659-.017c.319.024.548-.255.55-.541V6.33a.53.53 0 00-.546-.547h-7.16a.556.556 0 00-.214.042z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
        }
      }

      &::after {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-bottom: -0.1em;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet' width='20' height='20' fill='none' viewBox='0 0 20 20' aria-hidden='true'%3E%3Cpath fill='%23f5f5f5' fill-rule='evenodd' d='M6.263 5.825a.556.556 0 00-.181.12.498.498 0 00-.149.387l-.016.656v.006c0 .287.232.567.553.541h4.7l-5.364 5.363-.002.002a.545.545 0 00-.002.76l.483.483a.53.53 0 00.765 0l5.37-5.37V10.3l.01 3.197a.493.493 0 00.095.327.554.554 0 00.449.224l.659-.017c.319.024.548-.255.55-.541V6.33a.53.53 0 00-.546-.547h-7.16a.556.556 0 00-.214.042z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      }
    }

    p {
      color: var(--text-contrast-80);
      margin-block: 0.5rem;
    }
  }
}
